import { Locales } from "../../../localization/types";
import Presentation from "../ProductPresentation/assets/staff-presentation.png";
import PresentationKz from "../ProductPresentation/assets/staff-presentation_kz.png";
import PresentationBy from "../ProductPresentation/assets/staff-presentation_by.png";
import { externalLinks } from "../../../pages-data/_V2/common/links";


export const getStaffMeta = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			title: "Управление персоналом в ресторане и кафе | Quick Resto Казахстан",
			metaTags: [
				{
					name: "description",
					content:
						"Система контроля качества и объема работы сотрудников общепита ✔ Инструменты отслеживания подозрительных операций ✔ Учет рабочего времени и расчет зарплат ✔ Гибкая система ролей и прав доступа персонала → Внедрение под ключ. Автоматизируй бизнес в Казахстане",
				},
				{
					property: "og:url",
					content: `${externalLinks.quickrestoKZ.href}staff/`,
				},
				{
					property: "og:title",
					content: "Управление персоналом в ресторане и кафе | Quick Resto Казахстан",
				},
				{
					property: "og:description",
					content:
						"Система контроля качества и объема работы сотрудников общепита ✔ Инструменты отслеживания подозрительных операций ✔ Учет рабочего времени и расчет зарплат ✔ Гибкая система ролей и прав доступа персонала → Внедрение под ключ. Автоматизируй бизнес в Казахстане",
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:image",
					content: `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
				},
			],
			canonical: `${externalLinks.quickrestoKZ.href}staff/`,
		};
	}

	if (locale === "ru-BY") {
		return {
			title: "Управление персоналом в ресторане и кафе | Quick Resto Беларусь",
			metaTags: [
				{
					name: "description",
					content:
						"Система контроля качества и объема работы сотрудников общепита ✔ Инструменты отслеживания подозрительных операций ✔ Учет рабочего времени и расчет зарплат ✔ Гибкая система ролей и прав доступа персонала → Внедрение под ключ. Автоматизируй бизнес в Беларуси",
				},
				{
					property: "og:url",
					content: `${externalLinks.quickrestoBY.href}staff/`,
				},
				{
					property: "og:title",
					content: "Управление персоналом в ресторане и кафе | Quick Resto Беларусь",
				},
				{
					property: "og:description",
					content:
						"Система контроля качества и объема работы сотрудников общепита ✔ Инструменты отслеживания подозрительных операций ✔ Учет рабочего времени и расчет зарплат ✔ Гибкая система ролей и прав доступа персонала → Внедрение под ключ. Автоматизируй бизнес в Беларуси",
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:image",
					content: `${externalLinks.quickrestoBY.href}${PresentationBy}`,
				},
			],
			canonical: `${externalLinks.quickrestoBY.href}staff/`,
		};
	}

	return {
		title: "Управление персоналом в ресторане и кафе | Quick Resto",
		metaTags: [
			{
				name: "description",
				content:
					"Система контроля качества и объема работы сотрудников общепита  ✔ Инструменты отслеживания подозрительных операций ✔ Учет рабочего времени и расчет зарплат ✔ Гибкая система ролей и прав доступа персонала → Внедрение под ключ",
			},
			{
				property: "og:url",
				content: `${externalLinks.quickrestoRU.href}staff/`,
			},
			{
				property: "og:title",
				content: "Управление персоналом в ресторане и кафе — программа для официантов | Quick Resto",
			},
			{
				property: "og:description",
				content:
					" Система контроля официантов под ключ ✔Отслеживание подозрительных операций ✔Учет рабочего времени и расчет зарплат ✔Гибкая система ролей и прав доступа персонала",
			},
			{
				property: "og:type",
				content: "website",
			},
			{
				property: "og:image",
				content: `${externalLinks.quickrestoRU.href}${Presentation}`,
			},
		],
		canonical: `${externalLinks.quickrestoRU.href}staff/`,
	};
};
