import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import classNames from "classnames"
import styles from "./styles.module.scss"

import { pagesLinks } from "../common/links"
import { Locales } from "../../../localization/types";

export const getFeaturesData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				title: "Дай возможность заработать больше",
				description: (
					<p className={styles.appDescription1}>
						Подключим систему электронных чаевых: &laquo;Нетмонет&raquo;, CloudTips
						или любую другую. Гость проходит по&nbsp;QR-коду на&nbsp;чеке
						и&nbsp;оставляет благодарность конкретному официанту.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/staff-feature-1_kz.webp"
						alt="электронные чаевые"
						breakpoints={[380, 668, 840, 700]}
						sizes="(max-width: 500px) 380px, (max-width: 800px) 668px, (max-width: 1319px) 840px, (min-width: 1320px) 700px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.feature1,
				theme: "blueSkyLight",
				bottomContent: (
					<div className={styles.notes}>
						<a
							href="https://netmonet.co/partnership?promocode=qresto"
							className={classNames(styles.note, styles.link)}
							target="_blank"
							rel="noreferrer"
						>
							<StaticImage
								src="../../../assets/images/netmonet.png"
								alt=""
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</a>
						<a
							href="https://lk.cloudtips.ru/sign-up?sales=3525&agent=quickresto"
							className={classNames(styles.note, styles.link)}
							target="_blank"
							rel="noreferrer"
						>
							<StaticImage
								src="../../../assets/images/cloudtips.png"
								alt=""
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</a>
					</div>
				),
			},
			{
				category: "оповещения",
				title: (
					<>
						Если кто-то
						<br />
						не&nbsp;чист на&nbsp;руку
					</>
				),
				tabletTitle: <>Если кто-то не&nbsp;чист на&nbsp;руку</>,
				description: (
					<p className={styles.appDescription2}>
						Первым узнавай о&nbsp;самом важном: например, выручке за&nbsp;смену или
						отменах. По&nbsp;электронной почте, в&nbsp;мобильном приложении или
						прямо в&nbsp;Telegram.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image2}
						src="./assets/staff-feature-2_kz.png"
						alt="уведомления об опасных операциях"
						breakpoints={[440, 610, 720, 630]}
						sizes="(max-width: 500px) 440px, (max-width: 800px) 610px, (max-width: 1319px) 720px, (min-width: 1320px) 630px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				reverse: true,
				className: styles.feature2,
				theme: "purple",
				actionText: "Подробнее",
				actionLink: pagesLinks.notifications.href,
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				title: "Көбірек ақша табу мүмкіндігін беріңіз ",
				description: (
					<p className={styles.appDescription1}>
						Электрондық шайпұл жүйесін қосамыз: «Нетмонет», CloudTips немесе кез келген басқасын. Қонақ чектегі QR-код бойынша өтеді және нақты даяшыға алғыс қалдырады.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/staff-feature-1_kz.webp"
						alt="электронные чаевые"
						breakpoints={[380, 668, 840, 700]}
						sizes="(max-width: 500px) 380px, (max-width: 800px) 668px, (max-width: 1319px) 840px, (min-width: 1320px) 700px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.feature1,
				theme: "blueSkyLight",
				bottomContent: (
					<div className={styles.notes}>
						<a
							href="https://netmonet.co/partnership?promocode=qresto"
							className={classNames(styles.note, styles.link)}
							target="_blank"
							rel="noreferrer"
						>
							<StaticImage
								src="../../../assets/images/netmonet.png"
								alt=""
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</a>
						<a
							href="https://lk.cloudtips.ru/sign-up?sales=3525&agent=quickresto"
							className={classNames(styles.note, styles.link)}
							target="_blank"
							rel="noreferrer"
						>
							<StaticImage
								src="../../../assets/images/cloudtips.png"
								alt=""
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</a>
					</div>
				),
			},
			{
				category: "хабарлау",
				title: (
					<>
						Егер біреудің <br />ниеті таза болмаса
					</>
				),
				tabletTitle: <>Егер біреудің ниеті таза болмаса</>,
				description: (
					<p className={styles.appDescription2}>
						Ең маңызды: мысалы, ауысым түсімі немесе бас тартулар туралы бірінші болып біліңіз.
						Электрондық пошта арқылы, мобильді қосымшада немесе тікелей Telegram-да.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image2}
						src="./assets/staff-feature-2_kz.png"
						alt="уведомления об опасных операциях"
						breakpoints={[440, 610, 720, 630]}
						sizes="(max-width: 500px) 440px, (max-width: 800px) 610px, (max-width: 1319px) 720px, (min-width: 1320px) 630px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				reverse: true,
				className: styles.feature2,
				theme: "purple",
				actionText: "Толығырақ",
				actionLink: pagesLinks.notifications.href,
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				title: "Дай возможность заработать больше",
				description: (
					<p className={styles.appDescription1}>
						Подключим систему электронных чаевых: &laquo;Нетмонет&raquo;, CloudTips
						или любую другую. Гость проходит по&nbsp;QR-коду на&nbsp;чеке
						и&nbsp;оставляет благодарность конкретному официанту.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image1}
						src="./assets/staff-feature-1_by.webp"
						alt="электронные чаевые"
						breakpoints={[380, 668, 840, 700]}
						sizes="(max-width: 500px) 380px, (max-width: 800px) 668px, (max-width: 1319px) 840px, (min-width: 1320px) 700px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.feature1,
				theme: "blueSkyLight",
				bottomContent: (
					<div className={styles.notes}>
						<a
							href="https://netmonet.co/partnership?promocode=qresto"
							className={classNames(styles.note, styles.link)}
							target="_blank"
							rel="noreferrer"
						>
							<StaticImage
								src="../../../assets/images/netmonet.png"
								alt=""
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</a>
						<a
							href="https://lk.cloudtips.ru/sign-up?sales=3525&agent=quickresto"
							className={classNames(styles.note, styles.link)}
							target="_blank"
							rel="noreferrer"
						>
							<StaticImage
								src="../../../assets/images/cloudtips.png"
								alt=""
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</a>
					</div>
				),
			},
			{
				category: "оповещения",
				title: (
					<>
						Если кто-то
						<br />
						не&nbsp;молодец
					</>
				),
				tabletTitle: <>Если кто-то не&nbsp;молодец</>,
				description: (
					<p className={styles.appDescription2}>
						Первым узнавай о&nbsp;самом важном: например, выручке за&nbsp;смену или
						отменах. По&nbsp;электронной почте, в&nbsp;мобильном приложении или
						прямо в&nbsp;Telegram.
					</p>
				),
				img: (
					<StaticImage
						className={styles.image2}
						src="./assets/staff-feature-2_by.png"
						alt="уведомления об опасных операциях"
						breakpoints={[440, 610, 720, 630]}
						sizes="(max-width: 500px) 440px, (max-width: 800px) 610px, (max-width: 1319px) 720px, (min-width: 1320px) 630px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				reverse: true,
				className: styles.feature2,
				theme: "purple",
				actionText: "Подробнее",
				actionLink: pagesLinks.notifications.href,
			},
		]
	}

	return [
		{
			title: "Дай возможность официантам заработать больше",
			description: (
				<p className={styles.appDescription1}>
					Подключим систему электронных чаевых: &laquo;Нетмонет&raquo;, CloudTips
					или любую другую. Гость проходит по&nbsp;QR-коду на&nbsp;чеке
					и&nbsp;оставляет благодарность конкретному официанту.
				</p>
			),
			img: (
				<StaticImage
					className={styles.image1}
					src="./assets/staff-feature-1.png"
					alt="электронные чаевые"
					breakpoints={[380, 668, 840, 700]}
					sizes="(max-width: 500px) 380px, (max-width: 800px) 668px, (max-width: 1319px) 840px, (min-width: 1320px) 700px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),

			className: styles.feature1,
			theme: "blueSkyLight",
			bottomContent: (
				<div className={styles.notes}>
					<a
						href="https://netmonet.co/partnership?promocode=qresto"
						className={classNames(styles.note, styles.link)}
						target="_blank"
						rel="noreferrer"
					>
						<StaticImage
							src="../../../assets/images/netmonet.png"
							alt=""
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</a>
					<a
						href="https://lk.cloudtips.ru/sign-up?sales=3525&agent=quickresto"
						className={classNames(styles.note, styles.link)}
						target="_blank"
						rel="noreferrer"
					>
						<StaticImage
							src="../../../assets/images/cloudtips.png"
							alt=""
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</a>
				</div>
			),
		},
		{
			category: "оповещения",
			title: (
				<>
					Если кто-то
					<br />
					не&nbsp;чист на&nbsp;руку
				</>
			),
			tabletTitle: <>Если кто-то нечист на&nbsp;руку</>,
			description: (
				<p className={styles.appDescription2}>
					Первым узнавай о&nbsp;самом важном: например, выручке за&nbsp;смену или
					отменах. По&nbsp;электронной почте, в&nbsp;мобильном приложении или
					прямо в&nbsp;Telegram.
				</p>
			),
			img: (
				<StaticImage
					className={styles.image2}
					src="./assets/staff-feature-2.png"
					alt="уведомления об опасных операциях"
					breakpoints={[440, 610, 720, 630]}
					sizes="(max-width: 500px) 440px, (max-width: 800px) 610px, (max-width: 1319px) 720px, (min-width: 1320px) 630px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			reverse: true,
			className: styles.feature2,
			theme: "purple",
			actionText: "Подробнее",
			actionLink: pagesLinks.notifications.href,
		},
	]
}
