import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { pagesLinks, pagesLinksKz } from "../../../pages-data/_V2/common/links";
import styles from "./styles.module.scss";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			pageName: "Управление персоналом",
			desktopTitle: "Управляй самым \nценным ресурсом",
			subTitle: (
				<>
					<p className={styles.banner_desktop}>
						Поможем контролировать качество и&nbsp;объём работы сотрудников, давать
						индивидуальные задания на&nbsp;основе аналитики и&nbsp;отслеживать
						подозрительные операции.
					</p>
					<noindex className={styles.banner_mobile}>
						<p>
							Поможем контролировать качество и объём&nbsp;работы сотрудников, давать индивидуальные задания
							на&nbsp;основе аналитики и&nbsp;отслеживать подозрительные операции.
						</p>
					</noindex>
				</>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					alt="управление персоналов в ресторане"
					src="./assets/staff-presentation_kz.png"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
					className={styles.image}
				/>
			),
		};
	}

	if (locale === "kz-KZ") {
		return {
			pageName: "Жеке құрам менеджменті",
			desktopTitle: "Ең құнды\nресурсты басқарыңыз",
			subTitle: (
				<>
					<p className={styles.banner_desktop}>
						Қызметкерлер жұмысының сапасы мен көлемін бақылауға,
						аналитика негізінде жеке тапсырмалар беруге және күмәнді операцияларды қадағалауға көмектесеміз.
					</p>
					<noindex className={styles.banner_mobile}>
						<p>
							Қызметкерлер жұмысының сапасы мен көлемін бақылауға, аналитика негізінде жеке тапсырмалар
							беруге және күмәнді операцияларды қадағалауға көмектесеміз.
						</p>
					</noindex>
				</>
			),
			cta: pagesLinksKz.registration.text,
			desktopCtaDetails:
				"Тез, ыңғайлы және екі апта тегін",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					alt="управление персоналов в ресторане"
					src="./assets/staff-presentation_kz.png"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
					className={styles.image}
				/>
			),
		};
	}

	if (locale === "ru-BY") {
		return {
			pageName: "Управление персоналом",
			desktopTitle: "Управляй самым \nценным ресурсом",
			subTitle: (
				<>
					<p className={styles.banner_desktop}>
						Поможем контролировать качество и&nbsp;объём работы сотрудников, давать
						индивидуальные задания на&nbsp;основе аналитики и&nbsp;отслеживать
						подозрительные операции.
					</p>
					<noindex className={styles.banner_mobile}>
						<p>
							Поможем контролировать качество и объём&nbsp;работы сотрудников, давать индивидуальные задания
							на&nbsp;основе аналитики и&nbsp;отслеживать подозрительные операции.
						</p>
					</noindex>
				</>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					alt="управление персоналов в ресторане"
					src="./assets/staff-presentation_by.png"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
					className={styles.image}
				/>
			),
		};
	}

	return {
		pageName: "Управление персоналом",
		desktopTitle: "Как управлять самым ценным ресурсом",
		subTitle: (
			<p>
				Поможем контролировать качество и&nbsp;объём работы сотрудников, давать
				индивидуальные задания на&nbsp;основе аналитики и&nbsp;отслеживать
				подозрительные операции.
			</p>
		),
		cta: pagesLinks.registration.text,
		desktopCtaDetails:
			"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две \nнедели бесплатно.",
		ctaLink: pagesLinks.registration,
		figure: (
			<StaticImage
				alt="управление персоналов в ресторане"
				src="./assets/staff-presentation.png"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
				className={styles.image}
			/>
		),
	};
};
