import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import mediaContent from "./mediaContent.module.scss"
import classNames from "classnames";
import {Locales} from "../../../localization/types";

export const getData = (locale :Locales) => {
	if (locale === 'kz-KZ') {
		return [
			{
				desktopContentWidth: 510,
				tabName: "Қателерсіз",
				desktopTitle: <>Адами<br />факторды төмендетеміз</>,
				mobileTitle: <>Адами<br />факторды төмендетеміз</>,
				desktopContent: "Кафе даяшыларына арналған бағдарлама қателер санын азайтады және қызмет көрсетуді жеделдетеді. Тағам әлі таратуға түсіп үлгермеді, ал даяшы оның дайын екенін біледі және оны алып кетуге ұмтылады. Барлығы хабарламалардың арқасында.",
				mediaContent: (
					<>
						<StaticImage
							className={classNames(mediaContent.image)}
							src="./assets/fo-notifications.png"
							alt={"уведомления в приложении для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</>
				),
			},
			{
				desktopContentWidth: 510,
				tabName: "Еске салу",
				desktopTitle: <>Адами<br />факторды төмендетеміз</>,
				mobileTitle: <>Адами<br />факторды төмендетеміз</>,
				desktopContent: "Қызметкерлер қажетті қуыру дәрежесін көрсетуді ұмытпайды және өзекті емес жеңілдіктер туралы айтпайды. Мәзірді немесе акцияларды өзгерттіңіз бе - позициялар автоматты түрде жаңартылды. Кртопқа тұздықты ұсыну керек пе - бағдарлама жадына сақтайды.",
				mediaContent: (
					<>
						<StaticImage
							className={classNames(mediaContent.image)}
							src="./assets/fo-notifications.png"
							alt={"таргетированные рассылки в приложении"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</>
				),
			},
		]
	}

	return [
		{
			desktopContentWidth: 510,
			tabName: "Без ошибок",
			desktopTitle: <>Снижаем <br />человеческий фактор</>,
			mobileTitle: <>Снижаем <br />человеческий фактор</>,
			desktopContent: "Программа для официантов кафе снижает количество ошибок и ускоряет обслуживание. Блюдо ещё не успело попасть на раздачу, а официант уже знает, что оно готово, и бежит его забирать. Всё благодаря уведомлениям.",
			mediaContent: (
				<>
					<StaticImage
						className={classNames(mediaContent.image)}
						src="./assets/fo-notifications.png"
						alt={"уведомления в приложении для кафе"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				</>
			),
		},
		{
			desktopContentWidth: 510,
			tabName: "Напоминания",
			desktopTitle: <>Снижаем <br />человеческий фактор</>,
			mobileTitle: <>Снижаем <br />человеческий фактор</>,
			desktopContent: "Сотрудники не забывают указать нужную прожарку и не рассказывают про неактуальные скидки. Изменил меню или акции — позиции автоматически обновились. Нужно предложить соус к картошке — программа напомнит.",
			mediaContent: (
				<>
					<StaticImage
						className={classNames(mediaContent.image)}
						src="./assets/fo-notifications.png"
						alt={"таргетированные рассылки в приложении"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				</>
			),
		},
	]
}

export default {
	features: [
		{
			desktopContentWidth: 510,
			tabName: "Без ошибок",
			desktopTitle: <>Снижаем <br />человеческий фактор</>,
			mobileTitle: <>Снижаем <br />человеческий фактор</>,
			desktopContent: "Программа для официантов кафе снижает количество ошибок и ускоряет обслуживание. Блюдо ещё не успело попасть на раздачу, а официант уже знает, что оно готово, и бежит его забирать. Всё благодаря уведомлениям.",
			mediaContent: (
				<>
					<StaticImage
						className={classNames(mediaContent.image)}
						src="./assets/fo-notifications.png"
						alt={"уведомления в приложении для кафе"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				</>
			),
		},
		{
			desktopContentWidth: 510,
			tabName: "Напоминания",
			desktopTitle: <>Снижаем <br />человеческий фактор</>,
			mobileTitle: <>Снижаем <br />человеческий фактор</>,
			desktopContent: "Сотрудники не забывают указать нужную прожарку и не рассказывают про неактуальные скидки. Изменил меню или акции — позиции автоматически обновились. Нужно предложить соус к картошке — программа напомнит.",
			mediaContent: (
				<>
					<StaticImage
						className={classNames(mediaContent.image)}
						src="./assets/fo-notifications.png"
						alt={"таргетированные рассылки в приложении"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				</>
			),
		},
	],
} as {
	features: Array<FeatureGalleryItem>
}
