import React from "react"
import {Locales} from "../../../localization/types";

export const getAdvantagesSlides = (locale :Locales) => {
	if (locale === 'kz-KZ') {
		return [
			{
				title: "Жұмыс уақытын есепке алу",
				description:
					"Қызметкердің белгіленген жұмысын атқарғанын бірден көресіз. Ал жалақы автоматты түрде есептеледі - демек, бухгалтердің жұмыс сағаттарын үнемдеуге болады.",
			},
			{
				title: "Толық команда",
				description:
					"Әр қызметкер үшін бөлек лицензияға ақша жұмсаудың керегі жоқ. Жай ғана барлығын мекеменің ортақ аккаунтына қосасыз.",
			},
			{
				title: "Икемді рөлдер жүйесі",
				description:
					"Лауазымдардың кез келген санын жасап, әрқайсысы үшін өз кіру рұқсаттарын теңшеуге болады. Тіпті бір рөлдің ішінде де әртүрлі қызметкерлер үшін әртүрлі құқықтар болуы мүмкін.",
			},
		]
	}

	return [
		{
			title: "Учёт рабочего времени",
			description:
				"Сразу видишь, отработал&nbsp;ли сотрудник положенное. А&nbsp;зарплата рассчитывается автоматически&nbsp;&mdash; значит, можно сэкономить на&nbsp;рабочих часах бухгалтера.",
		},
		{
			title: "Полная команда",
			description:
				"Не&nbsp;нужно тратиться на&nbsp;отдельную лицензию для каждого сотрудника. Просто подключаешь всех к&nbsp;общему аккаунту заведения.",
		},
		{
			title: "Гибкая система ролей",
			description:
				"Можно создать любое количество должностей и&nbsp;для каждой настроить свои доступы. Даже внутри одной роли могут быть разные права для разных сотрудников.",
		},
	]
}

export default [
	{
		title: "Учёт рабочего времени",
		description:
			"Сразу видишь, отработал&nbsp;ли сотрудник положенное. А&nbsp;зарплата рассчитывается автоматически&nbsp;&mdash; значит, можно сэкономить на&nbsp;рабочих часах бухгалтера.",
	},
	{
		title: "Полная команда",
		description:
			"Не&nbsp;нужно тратиться на&nbsp;отдельную лицензию для каждого сотрудника. Просто подключаешь всех к&nbsp;общему аккаунту заведения.",
	},
	{
		title: "Гибкая система ролей",
		description:
			"Можно создать любое количество должностей и&nbsp;для каждой настроить свои доступы. Даже внутри одной роли могут быть разные права для разных сотрудников.",
	},
]
