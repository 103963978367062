import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import mediaContent from "./mediaContent.module.scss"
import classNames from "classnames"
import styles from "./style.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale :Locales) => {
	if (locale === 'kz-KZ') {
		return [
			{
				desktopContentWidth: 490,
				tabName: "Кертартпа жұмыссызы",
				desktopTitle: "Персоналды басқаруды автоматтандырыңыз",
				desktopContent: "Персоналды бақылауға уақытты жаратпаңыз - бизнесті дамытумен айналысыңыз. Автоматтандыру жүйесі кертартпа жұмысты өзіне алады.",
				mediaContent: (
					<div
						className={classNames(
							mediaContent.images__container,
							mediaContent.images__container1,
						)}
					>
						<StaticImage
							className={mediaContent.image1}
							src="./assets/bo-staff-image.png"
							alt={"уведомления в приложении для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
						/>
					</div>
				),
			},
			{
				desktopContentWidth: 490,
				tabName: "Жұмыс уақыты",
				desktopTitle: "Персоналды басқаруды автоматтандырыңыз",
				desktopContent: "Даяшыларға арналған бағдарламамен бірге қызметкерлердің жұмыс уақытын күн сайын бекітудің және айдың соңында есеп айырысуларды салыстырып тексерудің керегі жоқ. Quick Resto бұны автоматты түрде жасайды, әрбір кассир мен даяшы бойынша артық істелген сағаттарды және демалыстарды есепке алады.",
				mediaContent: (
					<div
						className={classNames(
							mediaContent.images__container,
							mediaContent.images__container2
						)}
					>
						<StaticImage
							className={mediaContent.image2}
							src="./assets/bo-staff-image.png"
							alt={"уведомления в приложении для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
						/>
					</div>
				),
			},
		]
	}

	return [
		{
			desktopContentWidth: 490,
			tabName: "Без рутины",
			desktopTitle: "Автоматизируй управление персоналом",
			mobileTitle: "Автоматизируй управление персоналом",
			desktopContent: "Не трать время на контроль персонала — занимайся развитием бизнеса. Система автоматизации возьмёт рутину на себя.",
			mediaContent: (
				<div
					className={classNames(
						mediaContent.images__container,
						mediaContent.images__container1,
					)}
				>
					<StaticImage
						className={mediaContent.image1}
						src="./assets/bo-staff-image.png"
						alt={"уведомления в приложении для кафе"}
						objectFit={"contain"}
						placeholder={"blurred"}
					/>
				</div>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Рабочее время",
			desktopTitle: "Автоматизируй управление персоналом",
			mobileTitle: "Автоматизируй управление персоналом",
			desktopContent: "С программой для официантов не нужно ежедневно фиксировать рабочее время сотрудников и сверять расчёты в конце месяца. Quick Resto сделает это автоматически, учтёт часы переработки и отгулы по каждому кассиру и официанту.",
			mediaContent: (
				<div
					className={classNames(
						mediaContent.images__container,
						mediaContent.images__container2
					)}
				>
					<StaticImage
						className={mediaContent.image2}
						src="./assets/bo-staff-image.png"
						alt={"уведомления в приложении для кафе"}
						objectFit={"contain"}
						placeholder={"blurred"}
					/>
				</div>
			),
		},
	]
}
export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Без рутины",
			desktopTitle: "Автоматизируй управление персоналом",
			mobileTitle: "Автоматизируй управление персоналом",
			desktopContent: "Не трать время на контроль персонала — занимайся развитием бизнеса. Система автоматизации возьмёт рутину на себя.",
			mediaContent: (
				<div
					className={classNames(
						mediaContent.images__container,
						mediaContent.images__container1,
					)}
				>
					<StaticImage
						className={mediaContent.image1}
						src="./assets/bo-staff-image.png"
						alt={"уведомления в приложении для кафе"}
						objectFit={"contain"}
						placeholder={"blurred"}
					/>
				</div>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Рабочее время",
			desktopTitle: "Автоматизируй управление персоналом",
			mobileTitle: "Автоматизируй управление персоналом",
			desktopContent: "С программой для официантов не нужно ежедневно фиксировать рабочее время сотрудников и сверять расчёты в конце месяца. Quick Resto сделает это автоматически, учтёт часы переработки и отгулы по каждому кассиру и официанту.",
			mediaContent: (
				<div
					className={classNames(
						mediaContent.images__container,
						mediaContent.images__container2
					)}
				>
					<StaticImage
						className={mediaContent.image2}
						src="./assets/bo-staff-image.png"
						alt={"уведомления в приложении для кафе"}
						objectFit={"contain"}
						placeholder={"blurred"}
					/>
				</div>
			),
		},
	],
} as {
	features: Array<FeatureGalleryItem>
}
