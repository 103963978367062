import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import pageStyles from "../styles/_V2/pagesStyles/styles.module.scss"
import styles from "./styles/_V2/staff.module.scss"
import StaffProductPresentation from "../pagesSections/staff/ProductPresentation"
import Advantages from "../components/_V2/Advantages"
import CRMFeatures from "../pagesSections/staff/CRMFeatures"
import StaffFeatures from "../pagesSections/staff/StaffFeatures"
import NotificationFeatures from "../pagesSections/staff/NotificationFeatures"
import AdditionalServices from "../pagesSections/index/AdditionalServices"
import CtaForm from "../pagesSections/index/CtaForm"
import BusinessTypes from "../components/_V2/BusinessTypes"
import KnowledgeBase from "../pagesSections/index/KnowledgeBase"
import { getStaffMeta } from '../pagesSections/staff/meta'

import { getAdvantagesSlides } from '../pages-data/_V2/staff/advantages'

import { businessTypes as getBusinessTypes } from '../pages-data/_V2/common/business-types-compact'
import { getFeaturesData } from '../pages-data/_V2/staff/features'
import SimpleFeatures from '../components/_V2/SimpleFeatures'
import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function StaffPage() {
  const localizationContext = useLocalizationContext()
  const metaInfo = getStaffMeta(localizationContext.locale)

  const businessTypes = getBusinessTypes(localizationContext.locale)
  const features = getFeaturesData(localizationContext.locale)

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={metaInfo.title}
      metaTags={metaInfo.metaTags}
      linkCanonical={metaInfo.canonical}
      mainWrapperClassName={pageStyles.pageWrapper}
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={metaInfo.title}
      schemaDescription={metaInfo.metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaInfo.metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <StaffProductPresentation />

      <Advantages
        items={getAdvantagesSlides(localizationContext.locale)}
        className={classNames(styles.staff__advantages, pageStyles.pageSection)}
      />

      <CRMFeatures className={pageStyles.pageSection} />

      <SimpleFeatures data={features} className={pageStyles.pageSection} />

		<StaffFeatures className={pageStyles.pageSection} />

		<NotificationFeatures className={pageStyles.pageSection} />

      <AdditionalServices className={classNames(styles.staff__services)} />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <BusinessTypes key={localizationContext.locale} data={businessTypes} className={pageStyles.pageSection} />

      <KnowledgeBase
        type="horizontal"
        className={classNames(pageStyles.pageSection, styles.staff__knowledge)}
      />
    </DefaultLayout>
  )
}
