import React from "react"
import { SolidTabsProps } from "../../../components/_V2/SolidTabs"
import styles from "./styles.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales): SolidTabsProps => {
	if (locale === 'ru-KZ') {
		return {
			items: [
				{
					tabName: "Прозрачность",
					desktopTitle: "Добавляй азарта",
					tabletTitle: "Добавь азарта",
					mobileTitle: "Добавь азарта",
					desktopContent: (
						<>
							Полные отчёты по&nbsp;каждому кассиру и&nbsp;официанту: продажи блюд,
							выручка и&nbsp;средний чек.
							<br />
							Сотрудник тоже видит, из&nbsp;чего складывается его вознаграждение:
							отработанные часы
							<br />
							и&nbsp;ставка, премии и&nbsp;штрафы, процент от&nbsp;выручки.
						</>
					),
					tabletContent: (
						<>
							Полные отчёты по&nbsp;каждому кассиру и&nbsp;официанту: продажи блюд,
							выручка и&nbsp;средний чек. <br />
							Сотрудник тоже видит, из&nbsp;чего складывается его вознаграждение:
							отработанные часы и&nbsp;ставка, премии и&nbsp;штрафы, процент
							от&nbsp;выручки.
						</>
					),
					mobileContent: (
						<>
							Полные отчёты по&nbsp;каждому кассиру и&nbsp;официанту: продажи блюд,
							выручка и&nbsp;средний чек. Сотрудник тоже видит, из&nbsp;чего
							складывается его вознаграждение: отработанные часы и&nbsp;ставка,
							премии и&nbsp;штрафы, процент от&nbsp;выручки.
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(styles.image, styles.image1, styles.image_one__kz)}
							src={"./assets/staff-crm-1_kz.webp"}
							alt={"отчет по кассирам"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Рост продаж",
					desktopTitle: "Сотрудник на своём месте",
					tabletTitle: "Сотрудник на своём месте",
					mobileTitle: "Сотрудник \nна своём месте",
					desktopContent: (
						<>
							Поможем отследить, в&nbsp;каком из&nbsp;заведений сети сотрудник
							работает лучше всего.
							<br />
							Руководителю эта информация поможет для составления графика работы
							<br />
							в&nbsp;каждой конкретной точке сети.
						</>
					),
					tabletContent: (
						<>
							Поможем отследить, в&nbsp;каком из&nbsp;заведений сети сотрудник
							работает лучше всего. Руководителю эта информация поможет для
							составления графика работы в&nbsp;каждой конкретной точке сети.
						</>
					),
					mobileContent: (
						<>
							Поможем отследить, в&nbsp;каком из&nbsp;заведений сети сотрудник
							работает лучше всего. Руководителю эта информация поможет для
							составления графика работы в&nbsp;каждой конкретной точке сети.
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(styles.image, styles.image2, styles.image_two__kz)}
							src={"./assets/staff-crm-2_kz.webp"}
							alt={"отчет по сотрудникам общепита"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "KPI",
					desktopTitle: "Ставь персональные задачи",
					tabletTitle: "Ставь персональные задачи",
					mobileTitle: "Ставь \nперсональные \nзадачи",
					desktopContent: (
						<>
							Например, официанту нужно интереснее рассказывать о&nbsp;блюде,
							<br />
							продажи которого у&nbsp;него проседают. Или активнее предлагать гостям
							круассаны,
							<br />
							если пекарь приготовил слишком много.
						</>
					),
					tabletContent: (
						<>
							Например, официанту нужно интереснее рассказывать о&nbsp;блюде,
							продажи которого у&nbsp;него проседают. Или активнее предлагать гостям
							круассаны, если пекарь приготовил слишком много.
						</>
					),
					mobileContent: (
						<>
							Например, официанту нужно интереснее рассказывать о&nbsp;блюде,
							продажи которого у&nbsp;него проседают. Или активнее предлагать гостям
							круассаны, если пекарь приготовил слишком много.
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(styles.image, styles.image3, styles.image_two__kz)}
							src={"./assets/staff-crm-3_kz.webp"}
							alt={"задачи официантов"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			items: [
				{
					tabName: "Ашықтық",
					desktopTitle: "Құмарлық қосыңыз",
					desktopContent: (
						<>
							Әр кассир мен даяшы бойынша толық есептер: тағамдардың сатылымдары, түсім және орташа чек.
							<br />
							Қызметкер де оның сыйақысы неден қалыптасатынын көріп тұрады: жұмыс істелген сағаттар мен мөлшерлеме, сыйақылар мен айыппұлдар, түсімнен пайыз.
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(styles.image, styles.image1, styles.image_one__kz)}
							src={"./assets/staff-crm-1_kz.webp"}
							alt={"отчет по кассирам"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Сатудың өсуі",
					desktopTitle: "Қызметкер өз орнында",
					desktopContent: (
						<>
							Қызметкер желінің қай мекемесінде ең жақсы жұмыс істейтін қадағалауға көмектесеміз. Басшыға бұл ақпарат желінің әрбір нақты нүктесінде жұмыс кестесін жасау
							үшін көмектеседі.
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(styles.image, styles.image2, styles.image_two__kz)}
							src={"./assets/staff-crm-2_kz.webp"}
							alt={"отчет по сотрудникам общепита"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "KPI",
					desktopTitle: "Дербес міндеттерді қойыңыз",
					mobileTitle: "Дербес\nміндеттерді\nқойыңыз",
					desktopContent: (
						<>
							Мысалы, даяшы сатылымдары төмендеп келетін тағам туралы қызығырақ айтуы керек.
							Немесе егер наубайханашы тым көп круассандарды дайындаса, оларды қонақтарға белсендірек ұсыну керек.
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(styles.image, styles.image3, styles.image_two__kz)}
							src={"./assets/staff-crm-3_kz.webp"}
							alt={"задачи официантов"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			items: [
				{
					tabName: "Прозрачность",
					desktopTitle: "Добавь мотивации",
					mobileTitle: "Добавь мотивации",
					tabletTitle: "Добавь мотивации",
					desktopContent: (
						<>
							Полные отчёты по&nbsp;каждому кассиру и&nbsp;официанту: продажи блюд,
							выручка и&nbsp;средний чек.
							<br />
							Сотрудник тоже видит, из&nbsp;чего складывается его вознаграждение:
							отработанные часы
							<br />
							и&nbsp;ставка, премии и&nbsp;штрафы, процент от&nbsp;выручки.
						</>
					),
					tabletContent: (
						<>
							Полные отчёты по&nbsp;каждому кассиру и&nbsp;официанту: продажи блюд,
							выручка и&nbsp;средний чек. <br />
							Сотрудник тоже видит, из&nbsp;чего складывается его вознаграждение:
							отработанные часы и&nbsp;ставка, премии и&nbsp;штрафы, процент
							от&nbsp;выручки.
						</>
					),
					mobileContent: (
						<>
							Полные отчёты по&nbsp;каждому кассиру и&nbsp;официанту: продажи блюд,
							выручка и&nbsp;средний чек. Сотрудник тоже видит, из&nbsp;чего
							складывается его вознаграждение: отработанные часы и&nbsp;ставка,
							премии и&nbsp;штрафы, процент от&nbsp;выручки.
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(styles.image, styles.image1, styles.image_one__kz)}
							src={"./assets/staff-crm-1_by.webp"}
							alt={"отчет по кассирам"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Рост продаж",
					desktopTitle: "Сотрудник на своём месте",
					tabletTitle: "Сотрудник на своём месте",
					mobileTitle: "Сотрудник \nна своём месте",
					desktopContent: (
						<>
							Поможем отследить, в&nbsp;каком из&nbsp;заведений сети сотрудник
							работает лучше всего.
							<br />
							Руководителю эта информация поможет для составления графика работы
							<br />
							в&nbsp;каждой конкретной точке сети.
						</>
					),
					tabletContent: (
						<>
							Поможем отследить, в&nbsp;каком из&nbsp;заведений сети сотрудник
							работает лучше всего. Руководителю эта информация поможет для
							составления графика работы в&nbsp;каждой конкретной точке сети.
						</>
					),
					mobileContent: (
						<>
							Поможем отследить, в&nbsp;каком из&nbsp;заведений сети сотрудник
							работает лучше всего. Руководителю эта информация поможет для
							составления графика работы в&nbsp;каждой конкретной точке сети.
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(styles.image, styles.image2, styles.image_two__kz)}
							src={"./assets/staff-crm-2_by.webp"}
							alt={"отчет по сотрудникам общепита"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "KPI",
					desktopTitle: "Ставь персональные задачи",
					mobileTitle: "Ставь персональные задачи",
					tabletTitle: "Ставь персональные задачи",
					desktopContent: (
						<>
							Например, официанту нужно интереснее рассказывать о&nbsp;блюде,
							<br />
							продажи которого у&nbsp;него проседают. Или активнее предлагать гостям
							круассаны,
							<br />
							если пекарь приготовил слишком много.
						</>
					),
					tabletContent: (
						<>
							Например, официанту нужно интереснее рассказывать о&nbsp;блюде,
							продажи которого у&nbsp;него проседают. Или активнее предлагать гостям
							круассаны, если пекарь приготовил слишком много.
						</>
					),
					mobileContent: (
						<>
							Например, официанту нужно интереснее рассказывать о&nbsp;блюде,
							продажи которого у&nbsp;него проседают. Или активнее предлагать гостям
							круассаны, если пекарь приготовил слишком много.
						</>
					),
					mediaContent: (
						<StaticImage
							className={classNames(styles.image, styles.image3, styles.image_two__kz)}
							src={"./assets/staff-crm-3_by.webp"}
							alt={"задачи официантов"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
			],
		}
	}

	return {
		items: [
			{
				tabName: "Прозрачность",
				desktopTitle: "Добавь азарта",
				mobileTitle: "Добавь азарта",
				tabletTitle: "Добавь азарта",
				desktopContent: (
					<>
						Полные отчёты по&nbsp;каждому кассиру и&nbsp;официанту: продажи блюд,
						выручка и&nbsp;средний чек.
						<br />
						Сотрудник тоже видит, из&nbsp;чего складывается его вознаграждение:
						отработанные часы
						<br />
						и&nbsp;ставка, премии и&nbsp;штрафы, процент от&nbsp;выручки.
					</>
				),
				tabletContent: (
					<>
						Полные отчёты по&nbsp;каждому кассиру и&nbsp;официанту: продажи блюд,
						выручка и&nbsp;средний чек. <br />
						Сотрудник тоже видит, из&nbsp;чего складывается его вознаграждение:
						отработанные часы и&nbsp;ставка, премии и&nbsp;штрафы, процент
						от&nbsp;выручки.
					</>
				),
				mobileContent: (
					<>
						Полные отчёты по&nbsp;каждому кассиру и&nbsp;официанту: продажи блюд,
						выручка и&nbsp;средний чек. Сотрудник тоже видит, из&nbsp;чего
						складывается его вознаграждение: отработанные часы и&nbsp;ставка,
						премии и&nbsp;штрафы, процент от&nbsp;выручки.
					</>
				),
				mediaContent: (
					<StaticImage
						className={classNames(styles.image, styles.image1, styles.image_one__ru)}
						src={"./assets/staff-crm-1.png"}
						alt={"отчет по кассирам"}
						objectFit="contain"
						breakpoints={[223, 642, 690]}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
			{
				tabName: "Рост продаж",
				desktopTitle: "Повысь вовлечённость официантов",
				mobileTitle: "Повысь вовлечённость официантов",
				tabletTitle: "Повысь вовлечённость официантов",
				desktopContent: (
					<>
						Поможем отследить, в&nbsp;каком из&nbsp;заведений сети сотрудник
						работает лучше всего.
						<br />
						Руководителю эта информация поможет для составления графика работы
						<br />
						в&nbsp;каждой конкретной точке сети.
					</>
				),
				tabletContent: (
					<>
						Поможем отследить, в&nbsp;каком из&nbsp;заведений сети сотрудник
						работает лучше всего. Руководителю эта информация поможет для
						составления графика работы в&nbsp;каждой конкретной точке сети.
					</>
				),
				mobileContent: (
					<>
						Поможем отследить, в&nbsp;каком из&nbsp;заведений сети сотрудник
						работает лучше всего. Руководителю эта информация поможет для
						составления графика работы в&nbsp;каждой конкретной точке сети.
					</>
				),
				mediaContent: (
					<StaticImage
						className={classNames(styles.image, styles.image2, styles.image_two__ru)}
						src={"./assets/staff-crm-2.png"}
						alt={"отчет по сотрудникам общепита"}
						objectFit="contain"
						breakpoints={[223, 642, 690]}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
			{
				tabName: "KPI",
				desktopTitle: "Поставь персональные задачи",
				mobileTitle: "Поставь персональные задачи",
				tabletTitle: "Поставь персональные задачи",
				desktopContent: (
					<>
						Например, официанту нужно интереснее рассказывать о&nbsp;блюде,
						<br />
						продажи которого у&nbsp;него проседают. Или активнее предлагать гостям
						круассаны,
						<br />
						если пекарь приготовил слишком много.
					</>
				),
				tabletContent: (
					<>
						Например, официанту нужно интереснее рассказывать о&nbsp;блюде,
						продажи которого у&nbsp;него проседают. Или активнее предлагать гостям
						круассаны, если пекарь приготовил слишком много.
					</>
				),
				mobileContent: (
					<>
						Например, официанту нужно интереснее рассказывать о&nbsp;блюде,
						продажи которого у&nbsp;него проседают. Или активнее предлагать гостям
						круассаны, если пекарь приготовил слишком много.
					</>
				),
				mediaContent: (
					<StaticImage
						className={classNames(styles.image, styles.image3, styles.image_two__ru)}
						src={"./assets/staff-crm-3.png"}
						alt={"задачи официантов"}
						objectFit="contain"
						breakpoints={[223, 642, 690]}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
		],
	}
}
