import React from "react"
import classNames from "classnames"

import { getData } from "./data"
import FeaturesGallery from "../../../components/_V2/FeaturesGallery"
import styles from "./style.module.scss"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function NotificationFeatures(props: Props) {
	const localizationContext = useLocalizationContext()
	const data = getData(localizationContext.locale)
	return (
		<div
			className={classNames(styles.wlMobileFeaturesSection, props.className)}
		>
			<FeaturesGallery
				className={styles.wlFeaturesGallery}
				theme="azure"
				features={data}
			/>
		</div>
	)
}
