import React from "react"
import SolidTabs, { SOLID_TABS_THEMES } from "../../../components/_V2/SolidTabs"

import classNames from "classnames"

import { getData } from "./data"
import styles from "./styles.module.scss"
import { useLocalizationContext } from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function CRMFeatures(props: Props) {
	const localizationContext = useLocalizationContext()

	return (
		<SolidTabs
			key={localizationContext.locale}
			className={classNames(props.className, styles.section)}
			items={getData(localizationContext.locale).items}
			theme={SOLID_TABS_THEMES.CYAN}
			transformOnAdaptive={true}
		/>
	)
}
