import React from "react"
import classNames from "classnames"

import { getData } from "./data"
import FeaturesGallery, {
	FEATURE_GALLERY_DIRECTION,
} from "../../../components/_V2/FeaturesGallery"
import styles from "./style.module.scss"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function StaffFeatures(props: Props) {
	const localizationContext = useLocalizationContext()
	const data = getData(localizationContext.locale)
	return (
		<div className={classNames(styles.wlSection, props.className)}>
			<FeaturesGallery
				className={styles.wlFeaturesGallery}
				theme="fuchsia-custom-backdrop"
				features={data}
				direction={FEATURE_GALLERY_DIRECTION.REVERSE}
				leftColumnClass={styles.leftColumn}
			/>
		</div>
	)
}
